<template>
  <div>
    <merchant-dashboard-table
      :fields="fields"
      :orders="merchantData.todayOrdars"
      table-title="Latest Orders"
    >
      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>
      <template #cell(merchant)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.merchant"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.merchant.logo"
            />
          </template>
          <span
            v-if="data.item.merchant"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.merchant.merchant_name }}
          </span>
        </b-media>
      </template>
      <template
        #cell(created_at)="data"
        class="text-center"
      >
        {{ timeAgo(data.item.created_at) }}
      </template>
      <template
        #cell(offer)="data"
        class="text-center"
      >
        <span v-if="data.item.offer"> {{ data.item.offer.offer_name }} </span> <br>
        <small
          v-if="data.item.offer"
          class="text-muted"
        > # {{ data.item.offer.offer_id }} </small>
      </template>
      <template
        #cell(status)="data"
        class="text-center"
      >
        <b-badge :variant="data.item.status === 'pending' ? 'light-warning' : 'light-primary'">
          {{ data.item.status }}
        </b-badge>
      </template>
    </merchant-dashboard-table>
  </div>
</template>

<script>
import MerchantDashboardTable from './MerchantDashboardTable.vue'

export default {
  components: { MerchantDashboardTable },
  props: {
    merchantData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'offer', label: 'Offer name' },
        {
          key: 'status',
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'merchant',
          label: 'Merchant',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'branch.branch_name', label: 'Branch' },
        { key: 'cost', label: 'Cost' },
        {
          key: 'created_at',
          label: 'Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    timeAgo(input) {
      const date = (input instanceof Date) ? input : new Date(input)
      const formatter = new Intl.RelativeTimeFormat('en')
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      }
      const secondsElapsed = (date.getTime() - Date.now()) / 1000
      // eslint-disable-next-line no-restricted-syntax
      for (const key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key]
          return formatter.format(Math.round(delta), key)
        }
      }
    },
  },
}
</script>

<style>

</style>
