<template>
  <b-card>
    <div>
      <b-card-title>
        {{ tableTitle }}
      </b-card-title>
      <b-table
        :items="orders"
        :fields="fields"
        show-empty
        responsive
        class="mb-0"
        bordered
      >
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot
            :name="slotName"
            v-bind="scope"
          />
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    orders: {
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: () => '',
    },
  },

}
</script>

<style>

</style>
