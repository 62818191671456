<template>
  <b-row>
    <static-cards :dashboard="dashboard" />
    <b-col
      cols="12"
      md="8"
      lg="11"
    >
      <latest-transactions
        :dashboard="dashboard.new_latest_transactions"
        @filter="getDahboard"
      />
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="2"
    />
    <b-col
      cols="12"
      md="6"
    >
      <top-clients-deposit-transactions :dashboard="dashboard.top_client_deposit_transaction" />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <top-clients-withdraw-transactions :dashboard="dashboard.top_client_withdraw_transaction" />
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <top-amount-transactions :dashboard="dashboard.top_amount_transaction" />
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StaticCards from './components/StaticCards.vue'
import LatestTransactions from './components/LatestTransactions.vue'
import TopClientsDepositTransactions from './components/TopClientsDepositTransactions.vue'
import TopClientsWithdrawTransactions from './components/TopClientsWithdraw.vue'
import TopAmountTransactions from './components/ClientTransactionsAmount.vue'

export default {
  components: {
    StaticCards, LatestTransactions, TopClientsDepositTransactions, TopClientsWithdrawTransactions, TopAmountTransactions,
  },
  data() {
    return {
      dashboard: {},
    }
  },
  mounted() {
    this.getDahboard()
  },
  setup() {
    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
    }
  },
  methods: {
    async getDahboard(params) {
      try {
        const { data } = await axios.get('dashboard', { params })
        this.dashboard = data.data
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style>

</style>
