<template>
  <b-row class="match-height">
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Branches"
        :statistic="merchantData.countBranches"
        icon="LayoutIcon"
      >
        <slot />
      </statistic-card-horizontal>
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Offers"
        :statistic="merchantData.offer"
        icon="ShoppingBagIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Total Revenu"
        :statistic="merchantData.totalRevenu"
        icon="ArrowUpRightIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: { StatisticCardHorizontal },
  props: {
    merchantData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
