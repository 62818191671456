<template>
  <b-card>
    <div>
      <b-card-title v-if="tableTitle">
        {{ tableTitle }}
      </b-card-title>
      <b-table
        :items="transactions"
        :fields="fields"
        show-empty
        responsive
        class="mb-0"
        bordered
        v-bind="tableOptions"
      >
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot
            :name="slotName"
            v-bind="scope"
          />
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    transactions: {
      type: Array,
      default: () => Array,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: () => '',
    },
    tableOptions: {
      type: Object,
      default: () => {},
    },
  },

}
</script>

<style>

</style>
