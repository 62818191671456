<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
      lg="12"
    >
      <static-cardss :merchant-data="merchantData" />
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <latest-orders :merchant-data="merchantData" />
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <latest-transactions :merchant-data="merchantData" />
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import StaticCardss from './components/StaticCards.vue'
import LatestOrders from './components/LatestOrders.vue'
import LatestTransactions from './components/LatestTransactions.vue'

export default {
  components: { StaticCardss, LatestOrders, LatestTransactions },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      merchantData: {},
      loader: false,
    }
  },
  mounted() {
    this.getMerchantData()
  },
  methods: {
    async getMerchantData() {
      this.loader = true
      try {
        const { data } = await axios.get(`merchant/dashboard/${this.userData.merchant_id}`)
        this.merchantData = data.data
      } catch (error) {
        // console.log(error)
      } finally {
        this.loader = false
      }
    },
  },

}
</script>

<style>

</style>
