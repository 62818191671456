<template>
  <div v-if="userData">
    <admin-dashboard v-if="userData.role === 0" />
    <merchant-dashboard
      v-if="userData.role === 1"
      :user-data="userData"
    />
  </div>
</template>

<script>
import AdminDashboard from './admin-dashboard/AdminDashboard.vue'
import MerchantDashboard from './merchant-dashboard/MerchantDashboard.vue'

export default {
  components: { AdminDashboard, MerchantDashboard },
  data() {
    return {
      userData: JSON.parse(localStorage.user_info),
    }
  },
}
</script>

<style>

</style>
