<template>
  <div>
    <admin-dashboard-table
      :transactions="dashboard"
      table-title="Top Amount Transaction"
      :fields="fields"
    >
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.user.profile_picture"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.user.profile_picture"
            />
          </template>
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.name }}
          </span>
        </b-media>
      </template>
      <template #cell(transaction_no)="data">
        #{{ data.item.transaction_no }}
      </template>
    </admin-dashboard-table>
  </div>
</template>

<script>
import AdminDashboardTable from './AdminDashboardTable.vue'

export default {
  components: { AdminDashboardTable },
  props: {
    dashboard: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const fields = [
      { key: 'transaction_no', label: 'Transaction NO.', sortable: true },
      { key: 'amount', label: 'Amount', sortable: true },
      { key: 'user.account_number', label: 'Account Number' },
      { key: 'user.name', label: 'Client Name' },
      { key: 'user.phone', label: 'Mobile Number' },
    ]

    return {
      fields,
    }
  },
}
</script>

<style>

</style>
