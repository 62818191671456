<template>
  <div>
    <admin-dashboard-table
      :transactions="dashboard"
      table-title="Top Clients Deposit"
      :fields="fields"
    >
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.user.profile_picture"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.user.profile_picture"
            />
          </template>
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.name }}
          </span>
          <small
            class="text-muted"
          >{{ data.item.user.phone }}</small>
        </b-media>
      </template>
    </admin-dashboard-table>
  </div>
</template>

<script>
import AdminDashboardTable from './AdminDashboardTable.vue'

export default {
  components: { AdminDashboardTable },
  props: {
    dashboard: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const fields = [
      { key: 'payable_id', label: '#', sortable: true },
      { key: 'user.account_number', label: 'Account Number' },
      { key: 'name', label: 'Client Name' },
    ]

    return {
      fields,
    }
  },
}
</script>

<style>

</style>
