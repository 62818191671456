<template>
  <b-row class="match-height">
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Shops"
        :statistic="dashboard.merchants"
        icon="ShoppingCartIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Customers"
        :statistic="dashboard.customers"
        icon="UsersIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Offers"
        :statistic="dashboard.offers"
        icon="ShoppingBagIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Branches"
        :statistic="dashboard.branches"
        icon="LayoutIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Transactions"
        :statistic="dashboard.transactions"
        icon="DollarSignIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Transfers"
        :statistic="dashboard.transfers"
        icon="GitPullRequestIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Merchant Users"
        :statistic="dashboard.merchant_users"
        icon="UserIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: { StatisticCardHorizontal },
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
  },

}
</script>

<style>

</style>
